.navbar {
  background-color: #182452;
  height: 97.5vh;
  width: rem(500px);
  padding: var(--mantine-spacing-md);
  display: flex;
  flex-direction: column;
  /* border-right: 1px solid skyblue; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbarMain {
  flex: 1;
  margin-top: var(--mantine-spacing-xl);
}

.title {
  text-transform: uppercase;
  letter-spacing: rem(-0.25px);
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: #ffffff;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  font-weight: 500;

  /* &:hover {
    background-color: light-dark(
      var(--mantine-color-gray-0),
      var(--mantine-color-dark-6)
    );
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

    & .linkIcon {
      color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    }
  } */

  &[data-active] {
    &,
    &:hover {
      /* background-color: #5754ed91; */
      color: #dc8585;
      font-weight: bold;

      & .linkIcon {
        color: #dc8585;
      }
    }
  }
}

.linkIcon {
  /* color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2)); */
  margin-right: var(--mantine-spacing-sm);
  width: rem(25px);
  height: rem(25px);
}

.footer {
  /* border-top: rem(1px) solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  padding-top: var(--mantine-spacing-md); */
}
