/* .DeviceList{
    border: "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
} */

.DeviceList {
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5;
  cursor: pointer;
  &:hover {
    background-color: #00000008;
  }
}
.igsLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px; /* Optional for spacing between search bar and logo */
}

.igsLogo img {
  width: 40px; /* Reduce size */
  height: 40px; /* Reduce size */
}