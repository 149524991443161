.container {
  display: flex;
  height: 100vh;
}

.leftPanel {
  flex: 1;
  background-color: #f0f0f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.illustrations {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.illustrationItem {
  margin: 0 10px;
}

.rightPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
}

.logo {
  text-align: center;
}

.logo img {
  width: 50px;
  height: 50px;
}

.logo h1 {
  margin-top: 10px;
  font-size: 24px;
  color: #333;
}

.form {
  width: 100%;
  max-width: 320px;
}

.inputGroup {
  margin-bottom: 15px;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.forgotPassword {
  text-align: right;
  margin-bottom: 20px;
}

.forgotPassword a {
  color: #007bff;
  text-decoration: none;
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #555;
}


.igsLogoContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; 
}

.igsLogo {
  width: 60px; /* Adjust the size as needed */
  height: 60px;
}